<template>
    <div class="home">
        <header class="header">
            <div class="header-content">
                <img src="@/assets/images/logo.png" alt="FindText Logo" class="logo" />
                <h1 class="app-name">FindText</h1>
            </div>
        </header>

        <main class="main-content">
            <div class="hero-section">
                <h2 class="title">
                    Fast Text Search
                    <br />
                    for Multiple Files
                </h2>
                <p class="description">Effortlessly find any text in seconds.</p>
                <p class="sub-description">
                    <span class="highlight">Free, private, and secure local search</span>
                </p>

                <div class="download-section">
                    <button v-for="(item, index) in list" :key="index" @click="download(item)" :class="['download-button', { disabled: !item.url }]">
                        <img src="@/assets/images/download.svg" alt="Download Icon" class="download-icon" />
                        {{ item.platform }}
                        <span class="arch">({{ item.arch }})</span>
                    </button>
                </div>
            </div>

            <div class="features-section">
                <h2 class="features-title">Key Features</h2>
                <div class="features-grid">
                    <div class="feature-card">
                        <div class="feature-icon">
                            <img src="@/assets/images/speed.svg" alt="Speed Icon" />
                        </div>
                        <h3>Lightning-Fast Search</h3>
                        <p>Scan gigabytes in milliseconds</p>
                    </div>
                    <div class="feature-card">
                        <div class="feature-icon">
                            <img src="@/assets/images/accuracy.svg" alt="Accuracy Icon" />
                        </div>
                        <h3>Powerful Regex Support</h3>
                        <p>Advanced pattern matching</p>
                    </div>
                    <div class="feature-card">
                        <div class="feature-icon">
                            <img src="@/assets/images/compatibility.svg" alt="Compatibility Icon" />
                        </div>
                        <h3>Multi-Format Compatibility</h3>
                        <p>Search across various file types</p>
                    </div>
                </div>
            </div>

            <div class="screenshots-section">
                <h2 class="section-title">Explore the Magic of FindText</h2>
                <div class="screenshots-container">
                    <div class="screenshot-wrapper left">
                        <img src="@/assets/images/screenshot1.png" alt="FindText Screenshot 1" class="screenshot" />
                        <div class="screenshot-info">
                            <h3>Intuitive Interface</h3>
                            <p>Clean design, easy to use</p>
                        </div>
                    </div>
                    <div class="screenshot-wrapper right">
                        <img src="@/assets/images/screenshot2.png" alt="FindText Screenshot 2" class="screenshot" />
                        <div class="screenshot-info">
                            <h3>Powerful Features</h3>
                            <p>Precise search, efficient results</p>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <footer class="footer">
            <p>&copy; 2024 FindText. All rights reserved.</p>
        </footer>
    </div>
</template>

<script>
const ClientVersion = '0.2.4';

import mixpanel from 'mixpanel-browser';
export default {
    data() {
        return {
            list: [
                {
                    platform: 'Windows',
                    arch: 'x64',
                    url: `https://cdn.findtext.app/FindText-${ClientVersion}-win-x64.exe`,
                },
                {
                    platform: 'Mac',
                    arch: 'Apple silicon',
                    url: `https://cdn.findtext.app/FindText-${ClientVersion}-mac-arm64.dmg`,
                },
                {
                    platform: 'Mac',
                    arch: 'Intel chip',
                    url: `https://cdn.findtext.app/FindText-${ClientVersion}-mac-x64.dmg`,
                },
            ],
        };
    },

    methods: {
        download(v) {
            if (!v.url) {
                return;
            }

            mixpanel.track('Download', { content: v });
            window.open(v.url, '_blank');
        },
    },
};
</script>

<style scoped>
.home {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    color: #333;
    line-height: 1.6;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    min-height: 100vh;
}

.header {
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(10px);
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
}

.header-content {
    display: flex;
    align-items: center;
    padding: 0.5rem 5%;
    max-width: 1200px;
    margin: 0 auto;
}

.logo {
    width: 30px;
    height: auto;
}

.app-name {
    font-size: 1.5rem;
    font-weight: 700;
    margin-left: 0.5rem;
    background: linear-gradient(45deg, #7d3dee, #3d9dff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.main-content {
    max-width: 1200px;
    margin: 0 auto;
    padding: 5rem 5% 4rem;
}

.hero-section {
    text-align: center;
    margin-bottom: 6rem;
}

.title {
    font-size: 3.5rem;
    font-weight: 800;
    margin-bottom: 1.5rem;
    line-height: 1.2;
    background: linear-gradient(45deg, #7d3dee, #3d9dff);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.description {
    font-size: 1.4rem;
    color: #555;
    margin-bottom: 1rem;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.sub-description {
    font-size: 1.2rem;
    color: #666;
    margin-bottom: 3rem;
}

.highlight {
    color: #3d9dff;
    font-weight: 600;
}

.download-section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 1.5rem;
}

.download-button {
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    background: linear-gradient(45deg, #7d3dee, #3d9dff);
    color: white;
    border: none;
    border-radius: 50px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(125, 61, 238, 0.3);
}

.download-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 6px 20px rgba(125, 61, 238, 0.4);
}

.download-button.disabled {
    background: linear-gradient(45deg, #ccc, #999);
    cursor: not-allowed;
    box-shadow: none;
}

.download-icon {
    width: 24px;
    margin-right: 0.8rem;
}

.arch {
    font-size: 0.9rem;
    opacity: 0.8;
}

.features-section {
    padding: 4rem 0;
    background-color: #f8f9fa;
}

.features-title {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 3rem;
    color: #2c3e50;
}

.features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 1rem;
}

.feature-card {
    background-color: white;
    border-radius: 12px;
    padding: 2rem;
    text-align: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.feature-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.feature-icon {
    width: 60px;
    height: 60px;
    margin: 0 auto 1.5rem;
    background-color: #f0f4ff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.feature-icon img {
    width: 30px;
    height: 30px;
}

.feature-card h3 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
    color: #2c3e50;
}

.feature-card p {
    font-size: 1rem;
    color: #34495e;
    line-height: 1.6;
}

.screenshots-section {
    padding: 8rem 0;
    background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
    overflow: hidden;
}

.section-title {
    text-align: center;
    font-size: 3rem;
    margin-bottom: 4rem;
    color: #2c3e50;
    font-weight: 800;
    letter-spacing: -1px;
}

.screenshots-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    max-width: 1400px;
    margin: 0 auto;
    position: relative;
}

.screenshot-wrapper {
    flex: 0 1 45%;
    position: relative;
    transition: all 0.5s ease;
}

.screenshot-wrapper.left,
.screenshot-wrapper.right {
    transform: perspective(1000px) rotateY(0deg);
}

.screenshot-wrapper:hover {
    transform: translateY(-20px);
    z-index: 1;
}

.screenshot {
    width: 100%;
    height: auto;
    border-radius: 20px;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
    transition: all 0.5s ease;
}

.screenshot-wrapper:hover .screenshot {
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.2);
}

.screenshot-info {
    position: absolute;
    bottom: -50px;
    left: 10%;
    right: 10%;
    background: rgba(255, 255, 255, 0.9);
    padding: 1.5rem;
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.5s ease;
}

.screenshot-wrapper:hover .screenshot-info {
    opacity: 1;
    transform: translateY(0);
}

.screenshot-info h3 {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
    color: #2c3e50;
}

.screenshot-info p {
    font-size: 1rem;
    color: #34495e;
}

.footer {
    text-align: center;
    padding: 2rem 0;
    color: #666;
    font-size: 0.9rem;
}
</style>
