import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

import mixpanel from 'mixpanel-browser';
mixpanel.init('3f03889391357f28c1a52e2c90b1d4a7', {
    debug: true,
    track_pageview: true,
    persistence: 'localStorage',
});

const app = createApp(App);
app.use(store);
app.use(router);
app.mount('#app');
